import {
  SET_ASSESSMENT_REQUESTS_TAB_VALUE,
  SEARCH_ASSESSMENT_REQUEST_LIST_LOADING,
  SEARCH_ASSESSMENT_REQUEST_LIST_SUCCESS,
  SEARCH_ASSESSMENT_REQUEST_LIST_FAILURE,
  SEARCH_VENDOR_LIST_LOADING,
  SEARCH_VENDOR_LIST_SUCCESS,
  SEARCH_VENDOR_LIST_FAILURE,
  SEARCH_ASSESSMENT_LIST_LOADING,
  SEARCH_ASSESSMENT_LIST_SUCCESS,
  SEARCH_ASSESSMENT_LIST_FAILURE,
  SEARCH_ASSESSMENT_REQUEST_PRODUCT_LIST_LOADING,
  SEARCH_ASSESSMENT_REQUEST_PRODUCT_LIST_SUCCESS,
  SEARCH_ASSESSMENT_REQUEST_PRODUCT_LIST_FAILURE,
  SET_VENDOR_LIST_PAGE,
  UPDATE_VENDOR_LIST,
  SEARCH_CLIENT_LIST_LOADING,
  SEARCH_CLIENT_LIST_SUCCESS,
  SEARCH_CLIENT_LIST_FAILURE,
  SET_CLIENT_LIST_PAGE,
  UPDATE_CLIENT_LIST,
  SET_PRODUCT_LIST_PAGE,
  UPDATE_PRODUCT_LIST,
  SEARCH_ASSESSMENT_CONFIG_LOADING,
  SEARCH_ASSESSMENT_CONFIG_SUCCESS,
  SEARCH_ASSESSMENT_CONFIG_FAILURE,
  SEARCH_ASSESSMENT_LIST_COUNT_LOADING,
  SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS,
  SEARCH_ASSESSMENT_LIST_COUNT_FAILURE,
  SET_ASSESSMENT_PAGE_NO,
  SET_ASSESSMENT_ROW_PER_PAGE,
  PUT_ASSESSMENT_REQUEST_FAILURE,
  PUT_ASSESSMENT_REQUEST_LOADING,
  PUT_ASSESSMENT_REQUEST_SUCCESS,
  SEARCH_ASSESSMENT_REQUEST_FAILURE,
  SEARCH_ASSESSMENT_REQUEST_LOADING,
  SEARCH_ASSESSMENT_REQUEST_SUCCESS,
  GET_EXTERNAL_TICKET_URL_LOADING,
  GET_EXTERNAL_TICKET_URL_FAILURE,
  GET_EXTERNAL_TICKET_URL_SUCCESS,
  SET_ASSESSMENT_REQUESTS_FILTERS,
  SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_LOADING,
  SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_SUCCESS,
  SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_FAILURE,
  SET_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST,
  SET_ASSESSMENT_REQUEST,
  SEARCH_DEFAULT_QT_REQUEST,
  SEARCH_DEFAULT_QT_SUCCESS,
  SEARCH_DEFAULT_QT_FAILIURE,
  SET_QUESTIONNAIRE_TEMPLATES,
} from 'actions/assessmentRequests';
import { isEmpty, isNil, uniqBy } from 'lodash';
import { ASSESSMENT_REQUEST_LIST_TAB_OPTIONS } from 'pages/AssessmentRequest/AssessmentRequestList/assessmentRequestListConstants';
import { getSelectOptionsList } from 'utils/arrayUtils';
import { convertToLocalDateTime } from 'utils/dateAndTimeUtils';
import { formatDistance, intlFormatDistance } from 'date-fns';
import { multiSelectFields, singleSelectFields } from 'pages/AssessmentRequest/AssessmentRequestReview/fields';
import {
  ASSESSMENT_REQUEST_CANCELLED_SUCCESSFULLY,
  ASSESSMENT_REQUEST_SUBMITTED_SUCCESSFULLY,
  ASSESSMENT_REQUEST_UPDATED_SUCCESSFULLY,
  ERROR_MESSAGE,
} from 'components/Common/AlertMessages';
import { toast } from 'react-toastify';
import {
  ASSESSMENT_REQUEST_CANCELLED,
  ASSESSMENT_REQUEST_IN_REVIEW,
  ASSESSMENT_REQUEST_SUBMITTED,
  MULTI_SELECT_RESPONSE_ID,
} from 'pages/AssessmentRequest/AssessmentRequestReview/constants';
import { DATE_FORMAT, DEFAULT_ROWS_PER_PAGE } from 'constants/constants';
import { DEAFULT_US_PHONE_CODE } from 'pages/VerifyVendor/constants';


const initialState = {
  loading: false,
  listLoading: false,
  assessmentRequestsTabValue: ASSESSMENT_REQUEST_LIST_TAB_OPTIONS.ASSESSMENTS,
  assessmentRequestList: [],
  assessmentRequest: {},
  assessmentList: [],
  vendorList: [],
  searchValueVendorList: [],
  vendorListPage: 1,
  clientList: [],
  searchValueClientList: [],
  clientListPage: 1,
  productList: [],
  searchValueProductList: [],
  productListPage: 1,
  assessmentConfigurationStatuses: [],
  externalTicketUrl: '',
  assessmentListCount: 0,
  assessmentPageNo: 0,
  assessmentRowsPerPage: DEFAULT_ROWS_PER_PAGE,
  assessmentRequestsFilters: {},
  productLoading: true,
  customerVendorProductList: [],
  securityQuestionnairesList: [],
  pageNoSecurityType: 0,
  questionnaireTemplateLoading: false,
  questionnaireTemplateList: [],
  pageNo: 0,
};

const multiSelectRequired = (v) => (!isEmpty(v) ? undefined : '* Required');

const getModifiedList = (actualList, completedAssessmentsCount = 0) => {
  let list = actualList;
  if (!isEmpty(actualList) && actualList?.length > 0) {
    list = actualList?.map((it, index) => {
      return {
        ...it,
        Id: index,
        LastStatusActivity:
          !isEmpty(it) && !isEmpty(it?.LastUpdated)
            ? intlFormatDistance(new Date(it?.LastUpdated), new Date())?.includes('quarter')
              ? formatDistance(new Date(it?.LastUpdated), new Date(), { addSuffix: true })
              : intlFormatDistance(new Date(it?.LastUpdated), new Date())
            : null,
      };
    });
  }
  return list;
};

export default function assessmentRequests(state = initialState, action) {
  const { type, payload, searchVendorValue, searchClientValue, searchProductValue, product } = action;

  switch (type) {
    case SET_ASSESSMENT_REQUESTS_TAB_VALUE: {
      return {
        ...state,
        assessmentRequestsTabValue: payload || ASSESSMENT_REQUEST_LIST_TAB_OPTIONS.REQUESTS,
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentRequestList: payload?.Data?.Rows?.map((item) => {
          return {
            ...item,
            InsertTimestamp: !isNil(item.InsertTimestamp) && convertToLocalDateTime(item.InsertTimestamp, DATE_FORMAT),
          };
        }),
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentRequestList: payload,
      };
    }

    case SEARCH_VENDOR_LIST_LOADING: {
      return {
        ...state,
        listLoading: true,
      };
    }

    case SEARCH_VENDOR_LIST_SUCCESS: {
      let searchValueVendorList = [];
      let currentVendorList = [];
      if (searchVendorValue) {
        searchValueVendorList = getSelectOptionsList(payload?.Data?.Rows, 'VendorOrgName', 'VendorOrgName');
      } else {
        currentVendorList = getSelectOptionsList(payload?.Data?.Rows, 'VendorOrgName', 'VendorOrgName');
      }

      return {
        ...state,
        listLoading: false,
        vendorList: uniqBy([...state.vendorList, ...currentVendorList], 'value'),
        searchValueVendorList: uniqBy([...state.searchValueVendorList, ...searchValueVendorList], 'value'),
      };
    }

    case SEARCH_VENDOR_LIST_FAILURE: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case SEARCH_ASSESSMENT_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentList: getModifiedList(payload?.Data?.Rows),
      };
    }
    case SEARCH_ASSESSMENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentList: payload,
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_PRODUCT_LIST_LOADING: {
      return {
        ...state,
        listLoading: true,
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_PRODUCT_LIST_SUCCESS: {
      let searchValueProductList = [];
      let currentProductList = [];
      if (searchProductValue) {
        searchValueProductList = getSelectOptionsList(payload?.Data?.Rows, 'ProductName', 'ProductName');
      } else {
        currentProductList = getSelectOptionsList(payload?.Data?.Rows, 'ProductName', 'ProductName');
      }
      return {
        ...state,
        listLoading: false,
        productList: uniqBy([...state.productList, ...currentProductList], 'value'),
        searchValueProductList: uniqBy([...state.searchValueProductList, ...searchValueProductList], 'value'),
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_PRODUCT_LIST_FAILURE: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case SET_VENDOR_LIST_PAGE: {
      return {
        ...state,
        vendorListPage: payload,
      };
    }

    case UPDATE_VENDOR_LIST: {
      return {
        ...state,
        searchValueVendorList: uniqBy(payload, 'value'),
      };
    }

    case SEARCH_CLIENT_LIST_LOADING: {
      return {
        ...state,
        listLoading: true,
      };
    }

    case SEARCH_CLIENT_LIST_SUCCESS: {
      let searchValueClientList = [];
      let currentClientList = [];
      if (searchClientValue) {
        searchValueClientList = getSelectOptionsList(payload?.Data?.Rows, 'ClientOrgName', 'OrgId');
      } else {
        currentClientList = getSelectOptionsList(payload?.Data?.Rows, 'ClientOrgName', 'OrgId');
      }

      return {
        ...state,
        listLoading: false,
        clientList: uniqBy([...state.clientList, ...currentClientList], 'value'),
        searchValueClientList: uniqBy([...state.searchValueClientList, ...searchValueClientList], 'value'),
      };
    }

    case SEARCH_CLIENT_LIST_FAILURE: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case SET_CLIENT_LIST_PAGE: {
      return {
        ...state,
        clientListPage: payload,
      };
    }

    case UPDATE_CLIENT_LIST: {
      return {
        ...state,
        searchValueClientList: uniqBy(payload, 'value'),
      };
    }

    case SET_PRODUCT_LIST_PAGE: {
      return {
        ...state,
        productListPage: payload,
      };
    }

    case UPDATE_PRODUCT_LIST: {
      return {
        ...state,
        searchValueProductList: uniqBy(payload, 'value'),
      };
    }

    case SEARCH_ASSESSMENT_CONFIG_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentConfigurationStatuses: getSelectOptionsList(payload?.Data?.Rows, 'AssessmentDisplayStatus', 'AssessmentDisplayStatus'),
      };
    }

    case SEARCH_ASSESSMENT_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentConfigurationStatuses: [],
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_LOADING:
    case PUT_ASSESSMENT_REQUEST_LOADING:
    case GET_EXTERNAL_TICKET_URL_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_ASSESSMENT_REQUEST_SUCCESS: {
      let ToggleKeys = [
        'AssessmentRequestCategoryId',
        'AssessmentRequestTypeId',
        'ScopingInformationId',
        'SecurityControlInformationId',
        'IsAssessmentValidated',
      ];
      let identifiersKeys = ['BusinessDomain', 'BusinessCapabilityGroup'];
      const tempAssessmentRequest = {};
      const fields = [];
      const responseText = {};
      Object.entries(payload).forEach((item) => {
        const [key, value] = item;
        if (ToggleKeys.includes(key) && !isNil(value)) {
          tempAssessmentRequest[`${key}`] = value.toString();
        } else if (identifiersKeys.includes(key) && !isNil(value)) {
          tempAssessmentRequest[`${key}`] = value.split(',');
        } else {
          tempAssessmentRequest[`${key}`] = value;
        }
      });

      payload?.AssessmentRequestQuestionList?.length > 0 &&
        payload?.AssessmentRequestQuestionList?.forEach((question) => {
          const fieldType = question.AssessmentRequestQuestionResponseTypeId === MULTI_SELECT_RESPONSE_ID ? multiSelectFields[0] : singleSelectFields[0];
          fields.push({
            ...fieldType,
            questionId: question.AssessmentRequestQuestionId,
            label: question?.IsRequired ? `${question.QuestionText} *` : question.QuestionText,
            name: `ResponseText${question.AssessmentRequestQuestionId}`,
            required: question?.IsRequired,
            options: getSelectOptionsList(question.AssessmentRequestQuestionResponseOptionList, 'ResponseOptionText', 'ResponseOptionText'),
            validate: question?.IsRequired ? [multiSelectRequired] : [],
            optionSelected: question?.AssessmentRequestQuestionResponseList,
            DisplayOrder: question.DisplayOrder,
          });
          responseText[`ResponseText${question.AssessmentRequestQuestionId}`] = question?.AssessmentRequestQuestionResponseList[0]?.ResponseText?.split(',');
        });

      let tempProduct = {};
      if (product?.ProductVendorId) {
        tempProduct = {
          label: product?.ProductName,
          value: product?.ProductVendorId,
        };
      } else {
        tempProduct = {
          label: tempAssessmentRequest?.ProductName ?? '',
          value: 0,
        };
      }

      return {
        ...state,
        loading: false,
        assessmentRequest: {
          ...tempAssessmentRequest,
          ...responseText,
          AssessmentRequestUploadedFileList: payload?.AssessmentRequestUploadedFile,
          IsProductMedicalDevice: payload?.IsProductMedicalDevice?.toString(),
          Product: tempProduct,
          VendorContactCountryCode: isNil(payload?.VendorContactCountryCode) ? DEAFULT_US_PHONE_CODE : payload?.VendorContactCountryCode,
        },
        questionsList: payload,
        questionsDisplayFields: fields.sort((a, b) => a.DisplayOrder - b.DisplayOrder),
      };
    }
    case PUT_ASSESSMENT_REQUEST_SUCCESS: {
      let { statusId } = action;
      let toastMessage;
      if (statusId === ASSESSMENT_REQUEST_IN_REVIEW) {
        toastMessage = ASSESSMENT_REQUEST_UPDATED_SUCCESSFULLY;
      } else if (statusId === ASSESSMENT_REQUEST_CANCELLED) {
        toastMessage = ASSESSMENT_REQUEST_CANCELLED_SUCCESSFULLY;
      } else if (statusId === ASSESSMENT_REQUEST_SUBMITTED) {
        toastMessage = ASSESSMENT_REQUEST_SUBMITTED_SUCCESSFULLY;
      }
      toast.success(toastMessage);
      return {
        ...state,
        loading: false,
      };
    }
    case GET_EXTERNAL_TICKET_URL_SUCCESS: {
      return {
        ...state,
        loading: false,
        externalTicketUrl: payload,
      };
    }
    case SEARCH_ASSESSMENT_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case PUT_ASSESSMENT_REQUEST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case GET_EXTERNAL_TICKET_URL_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SEARCH_ASSESSMENT_LIST_COUNT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentListCount: payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows[0]?.CountOfAssessment,
      };
    }

    case SEARCH_ASSESSMENT_LIST_COUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentListCount: payload,
      };
    }

    case SET_ASSESSMENT_PAGE_NO: {
      return {
        ...state,
        assessmentPageNo: payload,
      };
    }

    case SET_ASSESSMENT_ROW_PER_PAGE: {
      return {
        ...state,
        assessmentRowsPerPage: payload,
      };
    }

    case SET_ASSESSMENT_REQUESTS_FILTERS: {
      return {
        ...state,
        assessmentRequestsFilters: payload,
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_LOADING: {
      return {
        ...state,
        productLoading: true,
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        productLoading: false,
        customerVendorProductList: getSelectOptionsList(payload?.Data?.Rows, 'ProductName', 'ProductVendorId'),
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_FAILURE: {
      return {
        ...state,
        productLoading: false,
        customerVendorProductList: payload,
      };
    }

    case SET_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST: {
      return {
        ...state,
        customerVendorProductList: payload,
      };
    }

    case SET_ASSESSMENT_REQUEST: {
      return {
        ...state,
        assessmentRequest: payload,
      };
    }

    case SEARCH_DEFAULT_QT_REQUEST: {
      return {
        ...state,
        questionnaireTemplateLoading: true,
      };
    }

    case SEARCH_DEFAULT_QT_SUCCESS: {
      return {
        ...state,
        questionnaireTemplateLoading: false,
        ...payload,
      };
    }

    case SEARCH_DEFAULT_QT_FAILIURE: {
      return {
        ...state,
        questionnaireTemplateLoading: false,
      }
    }

    case SET_QUESTIONNAIRE_TEMPLATES: {
      return {
        ...state,
        questionnaireTemplateLoading: false,
        questionnaireTemplateList: [],
        pageNo: 0,
      }
    }

    default:
      return state;
  }
}
